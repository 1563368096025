// @flow
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Col, Form, Row, Table} from "antd";
import "moment/locale/ru";
import EditableInputCell from "../../../components/EditableCells/EditableInputCell";
import EditableSelectCell from "../../../components/EditableCells/EditableSelectCell";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;

const enabledTypes = [{ value: 1, name: "да" }, { value: 0, name: "нет" }];

class Priorities extends Component {
  constructor(props) {
    super(props);
  };

  render() {
    const {
      getFieldDecorator,
      customerId,
      timings,
      onCreateTiming,
      onEnableTiming,
      onChangeTimingFromState,
      onChangeTimingToState,
      onChangeTimingDurationInMinutes,
      onDeleteTiming,
    } = this.props;

    const columns = [
      {
        title: "Исходный статус",
        dataIndex: "from_status",
        render: (from_status, record) => {
          return (
            <div>
              {getFieldDecorator("from_status", {
                initialValue: from_status ? from_status : null,
              })(
                <SelectFetch
                  placeholder="Статус"
                  onChange={(it) => onChangeTimingFromState(record.id, it.id)}
                  selected={from_status ? from_status : null}
                  url={`/tickets/all_statuses`}
                />
              )}
            </div>
          )
        },
        width: "30%",
        align: "left"
      },
      {
        title: "Переходящий статус",
        dataIndex: "to_status",
        render: (to_status, record) => {
          return (
            <div>
              {getFieldDecorator("to_status", {
                initialValue: to_status ? to_status : null,
              })(
                <SelectFetch
                  placeholder="Статус"
                  onChange={(it) => onChangeTimingToState(record.id, it.id)}
                  selected={to_status ? to_status : null}
                  url={`/tickets/all_statuses`}
                />
              )}
            </div>
          )
        },
        width: "30%",
        align: "left"
      },
      {
        title: "Допустимый промежуток (мин)",
        dataIndex: "duration_in_minutes",
        render: (duration_in_minutes, record) => {
          return (
            <EditableInputCell
              recordId={record.id}
              value={duration_in_minutes}
              precision={0}
              onChange={onChangeTimingDurationInMinutes}
            />
          );
        },
        width: "25%",
        align: "right"
      },
      {
        title: "Использовать счетчик",
        dataIndex: "enabled",
        render: (enabled, record) => {
          return (
            <EditableSelectCell
              recordId={record.id}
              items={enabledTypes}
              value={enabled ? 1 : 0}
              onChange={(id, { value }) => onEnableTiming(id, !!value)}
              // onChange={onEnableTiming}
            />
          );
        },
        width: "10%",
        align: "center"
      },
      {
        title: "",
        dataIndex: "id",
        render: (id, record) => {
          return (
            <Button
              type="dashed"
              shape="circle"
              icon="close"
              onClick={() => onDeleteTiming(id)}
            />
          );
        },
        width: "5%",
        align: "center"
      },
    ];


    return (
      <>
        <Row style={{ paddingBottom: 10 }}>
          <Col>
            <Table
              rowClassName={() => "editable-row"}
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={timings}
              rowKey="id"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button key="add"
                    onClick={onCreateTiming}>
              Добавить
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

Priorities.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object)
};

export default Priorities;
