// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card, Tabs, Dropdown, Icon } from "antd";
import Statuses from "./Statuses";
import Title from "./Title";
import General from "./General";
import Entities from "./Entities";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

class CustomerForm extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      isNew,
      isLoading,
      customer,
      onClose,
      // general
      isUploadingLogo,
      onUploadLogo,
      onChangeName,
      onChangeWebsite,
      onChangePostalCode,
      onChangeCity,
      onChangeStreet,
      onChangeBuilding,
      // details
      onChangeInn,
      onChangeKpp,
      onChangeOgrn,
      // priorities
      onChangeResponseTime,
      onChangeUseOpenHours,
      entityTicketPriorities,
      onChangeEntitiesForEntityTicketPriorities,
      onChangeResponseTimeForEntityTicketPriorities,
      onChangeUseOpenHoursForEntityTicketPriorities,
      // contacts
      isContactsLoading,
      onSaveContact,
      onDeleteContact,
      // timings
      timings,
      onCreateTiming,
      onEnableTiming,
      onChangeTimingFromState,
      onChangeTimingToState,
      onChangeTimingDurationInMinutes,
      onDeleteTiming,
      // settings
      onChangeTicketLogs,
      onChangeTaskLogs,
      onChangeIndoundEmails,
      onChangeInboundDomain,
      onChangeInboundDomainTest,
      onChangeInboundEmail,
      onChangeInboundEmailTest,
      onChangeInboundEmailMember,
      onChangeUseAutoRate,
      onChangeAllowAllOrderTasks,
      onChangeCompletionCommentRequired,
    } = this.props;

    const operations = (
      <Link
        to={{
          pathname: "/entities/new",
          state: {
            customer: {
              id: customer ? customer.id : null,
              name: customer ? customer.name : null,
            },
          },
        }}
      >
        добавить объект
      </Link>
    );

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <Title
              isNew={isNew}
              isUploadingLogo={isUploadingLogo}
              name={customer.name}
              logo={customer.logo}
              onUploadLogo={onUploadLogo}
            />
          }
          extra={
            <div>
              <Button onClick={onClose}>Закрыть</Button>
              <Button
                onClick={this.onSubmit}
                type="primary"
                ghost
                style={{ marginLeft: "8px" }}
              >
                Сохранить
              </Button>
            </div>
          }
        >
          <Col span={13}>
            <Row>
              <Col span={24}>
                <General
                  isNew={isNew}
                  getFieldDecorator={getFieldDecorator}
                  customer={customer}
                  // general
                  onChangeName={onChangeName}
                  onChangeWebsite={onChangeWebsite}
                  onChangePostalCode={onChangePostalCode}
                  onChangeCity={onChangeCity}
                  onChangeStreet={onChangeStreet}
                  onChangeBuilding={onChangeBuilding}
                  // details
                  onChangeInn={onChangeInn}
                  onChangeKpp={onChangeKpp}
                  onChangeOgrn={onChangeOgrn}
                  // services
                  onChangeResponseTime={onChangeResponseTime}
                  onChangeUseOpenHours={onChangeUseOpenHours}
                  entityTicketPriorities={entityTicketPriorities}
                  onChangeEntitiesForEntityTicketPriorities={onChangeEntitiesForEntityTicketPriorities}
                  onChangeResponseTimeForEntityTicketPriorities={onChangeResponseTimeForEntityTicketPriorities}
                  onChangeUseOpenHoursForEntityTicketPriorities={onChangeUseOpenHoursForEntityTicketPriorities}
                  // timings
                  timings={timings}
                  onCreateTiming={onCreateTiming}
                  onEnableTiming={onEnableTiming}
                  onChangeTimingFromState={onChangeTimingFromState}
                  onChangeTimingToState={onChangeTimingToState}
                  onChangeTimingDurationInMinutes={onChangeTimingDurationInMinutes}
                  onDeleteTiming={onDeleteTiming}
                  // settings
                  onChangeTicketLogs={onChangeTicketLogs}
                  onChangeTaskLogs={onChangeTaskLogs}
                  onChangeIndoundEmails={onChangeIndoundEmails}
                  onChangeInboundDomain={onChangeInboundDomain}
                  onChangeInboundDomainTest={onChangeInboundDomainTest}
                  onChangeInboundEmail={onChangeInboundEmail}
                  onChangeInboundEmailTest={onChangeInboundEmailTest}
                  onChangeInboundEmailMember={onChangeInboundEmailMember}
                  onChangeUseAutoRate={onChangeUseAutoRate}
                  onChangeAllowAllOrderTasks={onChangeAllowAllOrderTasks}
                  onChangeCompletionCommentRequired={onChangeCompletionCommentRequired}
                />
              </Col>
            </Row>
            {isNew ? null : (
              <Row>
                <Col span={24}>
                  <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
                    <TabPane tab="Объекты" key="1">
                      <Entities entities={customer.entities} />
                    </TabPane>
                    <TabPane tab="Регламент" key="2">
                      Регламент
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={1} />
          <Col span={10}>
            <Row>
              <Statuses
                isNew={isNew}
                getFieldDecorator={getFieldDecorator}
                customer={customer}
                isContactsLoading={isContactsLoading}
                onSaveContact={onSaveContact}
                onDeleteContact={onDeleteContact}
              />
            </Row>
          </Col>
        </Card>
      </Form>
    );
  }
}

CustomerForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  customer: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default Form.create()(CustomerForm);
